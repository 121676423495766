


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import Avatar from '@/_components/avatar/avatar.vue';
import { mapGetters } from 'vuex';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TranslateResult } from 'vue-i18n';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TChatConfig } from '@/_modules/chat/types/chat-config.type';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';

@Component({
  name: 'chat-message',
  components: {
    Avatar
  },
  props: {
    // TODO: use decorator
    message: {
      type: Object as () => TChatMessage,
    }
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
    }),
  }
})
export default class ChatMessage extends Vue {

  public readonly ChatMessageType: typeof ChatMessageType = ChatMessageType;

  public readonly contact: TContact;
  public readonly event: TEvent;

  public message: TChatMessage;
  public chatContact: TChatContact;

  public get currentContactId(): string {
    // TODO: use store or something else
    return ChatHelper.getUserId(this.chatConfig);
  }

  public get isMine(): boolean {
    const currentContactId = this.currentContactId;
    if (!currentContactId || !this.message.from) {
      return false;
    }

    return currentContactId === this.message.from.id;
  }

  public get time(): string {
    return DateTimeHelper.messageTime(this.message.time, true);
  }

  public getKickedOutReason(reason: string): string | TranslateResult {
    switch(reason) {
      case 'multipleAccount':
        return this.$t('eventChat.kickedOutReasonMultipleAccount');

      default:
        return this.$t('eventChat.kickedOutReasonUnknown');
    }
  }

  private get chatConfig(): TChatConfig {
    if (!this.contact || !this.event) {
      return null;
    }
    return {
      event: this.event,
      contact: this.contact,
    };
  }
}
