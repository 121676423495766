// @ts-ignore
import TIM from 'tim-js-sdk';
import { BehaviorSubject, Subject } from 'rxjs';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IChatProvider } from '@/_modules/chat/types/chat-provider.interface';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import TencentHelper from '@/_helpers/tencent.helper';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { TChatTextMessagePayload } from '@/_modules/chat/types/chat-text-message-payload.type';

const TIM_APP_ID = parseInt(process.env.VUE_APP_TENCENT_TIM_APP_ID, 10);
const TIM_LOG_LEVEL = parseInt(process.env.VUE_APP_TENCENT_TIM_LOG_LEVEL, 10);
const TIM_SECRET_KEY = process.env.VUE_APP_TENCENT_TIM_SECRET_KEY;
const TIM_EXPIRE = parseInt(process.env.VUE_APP_TENCENT_TIM_EXPIRE_TIME, 10);
const ADMINISTRATOR_ID = process.env.VUE_APP_TENCENT_TIM_ADMINISTRATOR_ID;
const REST_BASE_URL = process.env.VUE_APP_TENCENT_TIM_REST_BASE_URL;
const REST_TIMEOUT = 10000;

const CUSTOM_PROFILE_KEY_COMPANY = 'company';
const CUSTOM_PROFILE_KEY_POSITION = 'position';
const CUSTOM_PROFILE_KEY_AREA = 'area';

class TencentChatProvider implements IChatProvider {

  public readonly connectStatus$: BehaviorSubject<ChatConnectStatus> = new BehaviorSubject<ChatConnectStatus>(ChatConnectStatus.DISCONNECTED);
  public readonly messagesReceived$: Subject<TChatMessage[]> = new Subject<TChatMessage[]>();
  public readonly kickedOut$: Subject<string> = new Subject<string>();

  private _tim: any; // The lib does not have typedefs, so "any"
  private _contact: TChatContact = null;
  private _isSdkReady: boolean = false;
  private _axios: AxiosInstance;

  constructor() {
    this._createAxiosInstance();
    this._createSdkInstance();
    this._subscribeToTimEvents();
  }

  public get connectStatus(): ChatConnectStatus {
    return this.connectStatus$.getValue();
  }

  public async disconnect(): Promise<void> {
    const connectStatus = this.connectStatus;
    if (connectStatus === ChatConnectStatus.DISCONNECTED) {
      return;
    }
    if (connectStatus === ChatConnectStatus.CONNECTING) {
      await this._waitForConnected();
    }
    if (connectStatus === ChatConnectStatus.DISCONNECTING) {
      await this._waitForDisconnected();
      return;
    }
    await this._disconnect();
  }

  public async connect(contact: TChatContact): Promise<void> {
    if (this._contact && this._contact.id !== contact.id) {
      await this.disconnect();
    }

    const connectStatus = this.connectStatus;
    if (this._contact && this._contact.id === contact.id) {
      if (
        connectStatus === ChatConnectStatus.CONNECTED
        || connectStatus === ChatConnectStatus.CONNECTING
      ) {
        await this._waitForConnected();
        return;
      }
    }

    await this._connect(contact);
  }

  public async updateContact(contact: TChatContact): Promise<void> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return;
    }
    if (this._contact.id !== contact.id) {
      return;
    }

    await this._tim.updateMyProfile({
      nick: contact.name || '',
      avatar: contact.avatar || '',
    });
  }

  public async joinGroup(groupId: string): Promise<boolean> {
    try {
      await this._tim.joinGroup({
        groupID: groupId,
      });
    } catch (error) {
      // TODO: test all cases
      return false;
    }
    return true;
  }

  public async createGroup(group: TChatGroup): Promise<void> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return;
    }

    const requestConfig: AxiosRequestConfig = {
      url: 'group_open_http_svc/create_group',
      method: 'POST',
      data: {
        GroupId: group.id,
        Owner_Account: ADMINISTRATOR_ID,
        Type: 'Public',
        Name: group.name,
        ApplyJoinOption: 'FreeAccess',
      }
    };
    await this._axios.request(requestConfig);
    // TODO: handle errors?
  }

  public async updateGroupContact(contact: TChatContact, group: TChatGroup): Promise<void> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return;
    }
    if (this._contact.id !== contact.id) {
      return;
    }

    await this._tim.setGroupMemberCustomField({
      userID: contact.id,
      groupID: group.id,
      memberCustomField: [
        {
          key: CUSTOM_PROFILE_KEY_COMPANY,
          value: contact.company || ''
        },
        {
          key: CUSTOM_PROFILE_KEY_POSITION,
          value: contact.position || ''
        },
        {
          key: CUSTOM_PROFILE_KEY_AREA,
          value: contact.area || ''
        },
      ]
    });
  }

  public async getGroupContactsList(groupId: string): Promise<TChatContact[]> {
    const chunkSize = 50;
    let currentOffset = 0;
    const contactsList: TChatContact[] = [];
    let sdkResult;
    let currentCount = 0;

    do {

      sdkResult = await this._tim.getGroupMemberList({
        groupID: groupId,
        count: chunkSize,
        offset: currentOffset,
      });
      currentOffset += chunkSize;

      if (
        sdkResult
        && typeof sdkResult.code !== 'undefined'
        && sdkResult.code === 0
        && sdkResult.data
        && sdkResult.data.memberList
        && sdkResult.data.memberList instanceof Array
      ) {
        currentCount = sdkResult.data.memberList.length;
        sdkResult.data.memberList.forEach((member: any) => {
          const chatContact = this._timMember2ChatContact(member);
          if (chatContact) {
            contactsList.push(chatContact);
          }
        });
      } else {
        currentCount = 0;
      }
    } while (currentCount === chunkSize);

    return contactsList;
  }

  public async sendTextGroupMessage(groupId: string, payload: TChatTextMessagePayload): Promise<TChatMessage> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return null;
    }

    const timMessage = this._tim.createTextMessage({
      to: groupId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload
    });

    const sendMessageResponse = await this._tim.sendMessage(timMessage);
    if (
      !sendMessageResponse || sendMessageResponse.code !== 0
      || !sendMessageResponse.data || !sendMessageResponse.data.message
    ) {
      return null;
    }

    return this._timMessage2ChatMessage(sendMessageResponse.data.message);
  }

  public async getGroupMessages(groupId: string): Promise<TChatMessage[]> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return [];
    }

    const messages: TChatMessage[] = [];

    // const test1Config: AxiosRequestConfig = {
    //   url: 'group_open_http_svc/modify_group_base_info',
    //   method: 'POST',
    //   data: {
    //     GroupId: 'event_143',
    //     ApplyJoinOption: 'FreeAccess',
    //   }
    // };
    // const test1Response = await this._axios.request(test1Config);
    // console.log('');
    // console.log('test1Response', test1Response);
    // console.log('');
    //
    // const testConfig: AxiosRequestConfig = {
    //   url: 'group_open_http_svc/get_group_info',
    //   method: 'POST',
    //   data: {
    //     GroupIdList: [ 'event_147' ],
    //   }
    // };
    // const testResponse = await this._axios.request(testConfig);
    // console.log('');
    // console.log('testResponse', testResponse);
    // console.log('');

    const membersRequestConfig: AxiosRequestConfig = {
      url: 'group_open_http_svc/get_group_member_info',
      method: 'POST',
      data: {
        GroupId: groupId,
        Limit: 10000,
        Offset: 0,
      }
    };
    const membersResponse = await this._axios.request(membersRequestConfig);
    if (!membersResponse || !membersResponse.data || !membersResponse.data.MemberList) {
      throw new Error('Could not get group members list from rest api.');
    }
    const contactsMap: Map<string, TChatContact> = new Map<string, TChatContact>();
    membersResponse.data.MemberList.forEach((member: any): void => {
      if (!member.Role || member.Role !== 'Member') {
        return;
      }
      const contactId = member.Member_Account || null;
      if (!contactId) {
        return;
      }
      const contact: TChatContact = {
        id: contactId,
        name: '',
        avatar: '',
        company: '',
        position: '',
        area: '',
      };
      if (member.AppMemberDefinedData) {
        member.AppMemberDefinedData.forEach(({ Key, Value }: { Key: string; Value: string }): void => {
          if (Key === 'area') {
            contact.area = Value || '';
          } else if (Key === 'company') {
            contact.company = Value || '';
          } else if (Key === 'position') {
            contact.position = Value || '';
          }
        });
      }
      this._setContactLink(contact);
      contactsMap.set(contactId, contact);
    });

    const memberIds = Array.from(contactsMap.keys());
    if (memberIds.length > 0) {
      const profilesRequestConfig: AxiosRequestConfig = {
        url: 'profile/portrait_get',
        method: 'POST',
        data: {
          To_Account: memberIds,
          TagList: [ 'Tag_Profile_IM_Nick', 'Tag_Profile_IM_Image' ],
        }
      };
      const profilesResponse = await this._axios.request(profilesRequestConfig);
      if (!profilesResponse || !profilesResponse.data || !profilesResponse.data.UserProfileItem) {
        throw new Error('Could not get member profiles list from rest api.');
      }
      profilesResponse.data.UserProfileItem.forEach((profile: any): void => {
        const contactId = profile.To_Account;
        const contact = contactsMap.get(contactId);
        if (!contact || !profile.ProfileItem) {
          return;
        }
        profile.ProfileItem.forEach(({ Tag, Value }: { Tag: string; Value: string }): void => {
          if (Tag === 'Tag_Profile_IM_Nick') {
            contact.name = Value || '';
          } else if (Tag === 'Tag_Profile_IM_Image') {
            contact.avatar = Value || '';
          }
        });
      });
    }

    let isFinished = false;
    let ReqMsgSeq = null;
    const ReqMsgNumber = 20;
    do {
      const messagesRequestConfig: AxiosRequestConfig = {
        url: 'group_open_http_svc/group_msg_get_simple',
        method: 'POST',
        data: {
          GroupId: groupId,
          ReqMsgNumber,
          ReqMsgSeq
        }
      };
      const messagesResponse = await this._axios.request(messagesRequestConfig);
      if (!messagesResponse || !messagesResponse.data || !messagesResponse.data.RspMsgList) {
        throw new Error('Could not get group messages from rest api.');
      }

      ReqMsgSeq = null;
      messagesResponse.data.RspMsgList.forEach((message: any): void => {
        const contactId = message.From_Account;
        const contact = contactsMap.get(contactId);
        ReqMsgSeq = message.MsgSeq - 1;
        if (!contact
          || !message.MsgBody
          || !message.MsgBody[0]
          || message.MsgBody[0].MsgType !== 'TIMTextElem'
        ) {
          return;
        }

        const chatMessage: TChatMessage = {
          type: ChatMessageType.TEXT,
          id: '' + message.MsgSeq,
          to: groupId,
          from: contact,
          time: new Date(message.MsgTimeStamp * 1000),
          payload: {
            text: message.MsgBody[0].MsgContent.Text || ''
          }
        };

        messages.push(chatMessage);
      });

      isFinished = !ReqMsgSeq || messagesResponse.data.RspMsgList.length < ReqMsgNumber;

    } while (!isFinished);

    return messages.reverse();
  }

  private async _disconnect(): Promise<void> {
    this.connectStatus$.next(ChatConnectStatus.DISCONNECTING);
    this._contact = null;
    this._isSdkReady = false;
    try {
      await this._tim.logout();
    } finally {
      this.connectStatus$.next(ChatConnectStatus.DISCONNECTED);
    }
  }

  private async _connect(contact: TChatContact): Promise<void> {
    this._contact = contact;
    this.connectStatus$.next(ChatConnectStatus.CONNECTING);

    try {
      await this._tim.login({
        userID: contact.id,
        userSig: this._getUserSig(contact.id),
      });
    } catch (error) {
      this.connectStatus$.next(ChatConnectStatus.DISCONNECTED);
      throw error;
    }

    await this._waitForSdkReady();
    this.connectStatus$.next(ChatConnectStatus.CONNECTED);
  }

  private async _waitForConnected(): Promise<void> {
    await new Promise<void>((resolve: () => void, reject: (reason?: any) => void): void => {
      const timeout = 10000;
      const startDate = new Date();
      const intervalId = window.setInterval(() => {
        if (this.connectStatus === ChatConnectStatus.CONNECTED) {
          window.clearInterval(intervalId);
          resolve();
        } else {
          const now = new Date();
          if (now.getTime() - startDate.getTime() > timeout) {
            window.clearInterval(intervalId);
            reject(new Error('Waiting for connected event timed out.'));
          }
        }
      }, 200);
    });
  }

  private async _waitForDisconnected(): Promise<void> {
    await new Promise<void>((resolve: () => void, reject: (reason?: any) => void): void => {
      const timeout = 10000;
      const startDate = new Date();
      const intervalId = window.setInterval(() => {
        if (this.connectStatus === ChatConnectStatus.DISCONNECTED) {
          window.clearInterval(intervalId);
          resolve();
        } else {
          const now = new Date();
          if (now.getTime() - startDate.getTime() > timeout) {
            window.clearInterval(intervalId);
            reject(new Error('Waiting for connected event timed out.'));
          }
        }
      }, 200);
    });
  }

  private async _waitForSdkReady(): Promise<void> {
    await new Promise<void>((resolve: () => void, reject: (reason?: any) => void): void => {
      const timeout = 10000;
      const startDate = new Date();
      const intervalId = window.setInterval(() => {
        if (this._isSdkReady) {
          window.clearInterval(intervalId);
          resolve();
        } else {
          const now = new Date();
          if (now.getTime() - startDate.getTime() > timeout) {
            window.clearInterval(intervalId);
            reject(new Error('Waiting for SDK ready event timed out.'));
          }
        }
      }, 200);
    });
  }

  private _createAxiosInstance(): void {
    const administratorUserSig = this._getUserSig(ADMINISTRATOR_ID);
    this._axios = axios.create({
      baseURL: REST_BASE_URL,
      timeout: REST_TIMEOUT,
    });
    this._axios.interceptors.request.use((config: AxiosRequestConfig) => {
      config.params = {
        sdkappid: TIM_APP_ID,
        identifier: ADMINISTRATOR_ID,
        usersig: administratorUserSig,
        random: Math.floor(4294967295 * Math.random()),
        contenttype: 'json',
      };
      return config;
    });
  }

  private _getUserSig(userId: string): string {
    return TencentHelper.generateUserSig(userId, TIM_APP_ID, TIM_SECRET_KEY, TIM_EXPIRE);
  }

  private _createSdkInstance(): void {
    this._tim = TIM.create({
      SDKAppID: TIM_APP_ID,
    });
    this._tim.setLogLevel(TIM_LOG_LEVEL);
  }

  private _subscribeToTimEvents(): void {
    this._tim.on(TIM.EVENT.SDK_READY, this._onSdkReady.bind(this));
    this._tim.on(TIM.EVENT.SDK_NOT_READY, this._onSdkNotReady.bind(this));
    this._tim.on(TIM.EVENT.MESSAGE_RECEIVED, this._onMessageReceived.bind(this));
    this._tim.on(TIM.EVENT.KICKED_OUT, this._onKickedOut.bind(this));

    // this._tim.on(TIM.EVENT.PROFILE_UPDATED, (event: any) => {
    //   console.log('');
    //   console.log('PROFILE_UPDATED', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.GROUP_LIST_UPDATED, (event: any) => {
    //   console.log('');
    //   console.log('GROUP_LIST_UPDATED', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, (event: any) => {
    //   console.log('');
    //   console.log('CONVERSATION_LIST_UPDATED', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.MESSAGE_REVOKED, (event: any) => {
    //   console.log('');
    //   console.log('MESSAGE_REVOKED', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, (event: any) => {
    //   console.log('');
    //   console.log('MESSAGE_READ_BY_PEER', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.BLACKLIST_UPDATED, (event: any) => {
    //   console.log('');
    //   console.log('BLACKLIST_UPDATED', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.ERROR, (event: any) => {
    //   console.log('');
    //   console.log('ERROR', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.NET_STATE_CHANGE, (event: any) => {
    //   console.log('');
    //   console.log('NET_STATE_CHANGE', event);
    //   console.log('');
    // });

    // this._tim.on(TIM.EVENT.SDK_RELOAD, (event: any) => {
    //   console.log('');
    //   console.log('SDK_RELOAD', event);
    //   console.log('');
    // });
  }

  private _onSdkReady(): void {
    // console.log('');
    // console.log('_onSdkReady');
    // console.log('');
    this._isSdkReady = true;
  }

  private _onSdkNotReady(): void {
    // console.log('');
    // console.log('_onSdkNotReady');
    // console.log('');
    this._isSdkReady = false;
  }

  private _onMessageReceived(event: any): void {
    if (!event || !event.data || !event.data.length) {
      return;
    }
    const newMessages: TChatMessage[] = [];
    event.data.forEach((timMessage: any) => {
      if (!this._isMessageIgnored(timMessage)) {
        newMessages.push(this._timMessage2ChatMessage(timMessage));
      }
    });
    this.messagesReceived$.next(newMessages);
  }

  private _onKickedOut(event: any): void {
    const reason = (event && event.data && event.data.type) || null;
    const now = new Date();
    const kickedOutMessage: TChatMessage = {
      type: ChatMessageType.CUSTOM,
      id: 'kicked-out-msg-' + now.getTime(),
      to: this._contact.id,
      payload: {
        description: 'kicked-out',
        data: { reason }
      },
      time: now,
    };
    this.messagesReceived$.next([ kickedOutMessage ]);
    this.kickedOut$.next(reason);
  }

  private _timMember2ChatContact(timMember: any): TChatContact {
    if (typeof timMember !== 'object' || !timMember.userID) {
      return null;
    }

    const chatContact: TChatContact = {
      id: '' + timMember.userID,
      name: '' + timMember.nick,
      avatar: '' + timMember.avatar,
      company: '',
      position: '',
      area: ''
    };

    this._setContactLink(chatContact);

    if (timMember.memberCustomField && timMember.memberCustomField.length) {
      timMember.memberCustomField.forEach(({ key, value }: any) => {
        switch (key) {
          case CUSTOM_PROFILE_KEY_COMPANY:
            chatContact.company = value;
            break;

          case CUSTOM_PROFILE_KEY_POSITION:
            chatContact.position = value;
            break;

          case CUSTOM_PROFILE_KEY_AREA:
            chatContact.area = value;
            break;
        }
      });
    }

    return chatContact;
  }

  private _setContactLink(contact: TChatContact): void {
    const matches = (/^event_(\d+)_contact_(\d+)$/gi).exec(contact.id);
    if (matches) {
      contact.link = {
        name: 'promo-page-contacts-contact',
        params: {
          eventId: matches[1],
          contact_id: matches[2],
        }
      };
    }
  }

  private _timMessage2ChatMessage(timMessage: any): TChatMessage {
    switch (timMessage.type) {

      case 'TIMTextElem':
        return {
          id: timMessage.ID,
          to: timMessage.to,
          type: ChatMessageType.TEXT,
          payload: timMessage.payload,
          from: timMessage.from && this._timMember2ChatContact({
            userID: timMessage.from,
            nick: timMessage.nick || '',
            avatar: timMessage.avatar || ''
          }),
          time: new Date(timMessage.time * 1000)
        };
        break;

      case 'TIMCustomElem':
        return {
          id: timMessage.ID,
          to: timMessage.to,
          type: ChatMessageType.CUSTOM,
          payload: timMessage.payload,
          from: timMessage.from && this._timMember2ChatContact({
            userID: timMessage.from,
            nick: timMessage.nick || '',
            avatar: timMessage.avatar || ''
          }),
          time: new Date(timMessage.time * 1000)
        };
        break;

    }

    return null;
  }

  private _isMessageIgnored(timMessage: any, ignoreCustom: boolean = false): boolean {
    if (!timMessage || !timMessage.from || timMessage.from === '@TIM#SYSTEM' || timMessage.from === 'user_0') {
      return true;
    }
    if (ignoreCustom && timMessage.type === 'TIMCustomElem') {
      return true;
    }
    return false;
  }

}

const tencentChatProvider = new TencentChatProvider();
export default tencentChatProvider;
