


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import ChatMessage from '@/_modules/chat/components/chat-message/chat-message.vue';

@Component({
  name: 'chat-messages',
  components: {
    ChatMessage,
  },
})
export default class ChatMessages extends Vue {

  @Prop({ type: Array, default: [] })
  public messages: TChatMessage[];

  private isFirstMessagesChange: boolean = true;

  public mounted(): void {
    this.checkScrollPosition();
  }

  @Watch('messages', { immediate: true })
  private onMessagesChanged(): void {
    this.checkScrollPosition();
  }

  private checkScrollPosition(): void {
    if (!this.$refs.container) {
      return;
    }
    const container = (this.$refs.container as HTMLDivElement);
    if (this.isFirstMessagesChange) {
      this.$nextTick(() => {
        container.scrollTo(0, container.scrollHeight);
      });
      this.isFirstMessagesChange = false;
    } else {
      if ((container.scrollTop + container.offsetHeight) / container.scrollHeight > 0.95) {
        this.$nextTick(() => {
          container.scrollTo(0, container.scrollHeight);
        });
      }
    }
  }

}
