import ContactHelper from '@/_helpers/contact.helper';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { TChatConfig } from '@/_modules/chat/types/chat-config.type';
import { TEvent } from '@/_types/event.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';

export default class ChatHelper {

  public static getChatContactFromConfig(config: TChatConfig): TChatContact {
    if (!config || !config.event || !config.contact) {
      return null;
    }
    const contactId = ChatHelper.getUserId(config);
    if (!contactId) {
      return null;
    }

    const link = {
      name: 'promo-page-contacts-contact',
      params: {
        eventId: '' + config.event.id,
        contact_id: '' + config.contact.id,
      }
    };

    let area = '';
    if (config.contact.city) {
      area = ('' + config.contact.city).trim();
    }
    if (config.contact.country) {
      const countryName = ('' + config.contact.country).trim();
      if (countryName) {
        area += (area ? ', ' : '') + countryName;
      }
    }

    return {
      id: contactId,
      name: ContactHelper.getFullName(config.contact),
      avatar: config.contact.photo_url || '',
      company: config.contact.company_name || '',
      position: config.contact.company_position || '',
      area,
      link,
    };
  }

  public static getProgramChatGroup(program: TConferenceProgram, chatType: ConferenceProgramChatType): TChatGroup {
    if (!program || !program.id) {
      return null;
    }
    const groupId = ChatHelper.getProgramGroupId(program, chatType);
    if (!groupId) {
      return null;
    }
    return {
      id: groupId,
      name: program.title || '',
    };
  }

  public static getEventChatGroup(event: TEvent): TChatGroup {
    if (!event || !event.id) {
      return null;
    }
    const groupId = ChatHelper.getEventGroupId(event);
    if (!groupId) {
      return null;
    }
    return {
      id: groupId,
      name: event.title || '',
    };
  }

  public static getUserId(config: TChatConfig): string {
    if (!config || !config.event || !config.contact) {
      return null;
    }
    return `event_${config.event.id}_contact_${config.contact.id}`;
  }

  public static getEventGroupId(event: TEvent): string {
    if (!event || !event.id) {
      return null;
    }
    return `event_${event.id}`;
  }

  public static getProgramGroupId(program: TConferenceProgram, chatType: ConferenceProgramChatType): string {
    if (!program || !program.id) {
      return null;
    }
    // TODO: prepend 'event_[eventId]_'
    return `program_${program.id}_${chatType}`;
  }

}
