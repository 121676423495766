


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import IconMessageSend from '@/_modules/icons/components/icon-message-send.vue';
import chatService from '@/_modules/chat/services/chat.service';

const PROGRESS_ANIMATION_TOTAL_DURATION = 5000;

@Component({
  name: 'chat-message-composer',
  components: {
    IconMessageSend,
  },
})
export default class ChatMessageComposer extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: String })
  public readonly groupId: string;

  public isSendingMessage: boolean = false;

  public isSendingThrottled: boolean = false;
  public isSendingThrottleVisible: boolean = false;
  public sendingThrottleStart: Date;
  public sendingThrottleInterval: number;
  public sendingThrottleRemainingSeconds: string = '0.0';
  public sendingThrottleProgressStyle: string = 'width: 0';

  public beforeDestroy(): void {
    if (this.sendingThrottleInterval) {
      window.clearInterval(this.sendingThrottleInterval);
    }
  }

  public throttleNextSending(): void {
    this.sendingThrottleStart = new Date();
    this.isSendingThrottled = true;

    this.sendingThrottleInterval = window.setInterval(() => {
      this.updateSendingThrottleProgress();
    }, 200);

    window.setTimeout(() => {
      this.unThrottleNextSending();
    }, PROGRESS_ANIMATION_TOTAL_DURATION);
  }

  public unThrottleNextSending(): void {
    this.isSendingThrottled = false;
    this.isSendingThrottleVisible = false;
    if (this.sendingThrottleInterval) {
      window.clearInterval(this.sendingThrottleInterval);
    }
    this.focusOnTextArea();
  }

  public updateSendingThrottleProgress(): void {
    if (!this.sendingThrottleStart) {
      this.sendingThrottleRemainingSeconds = '0.0';
    }
    const remainingMilliseconds = PROGRESS_ANIMATION_TOTAL_DURATION - ((new Date()).getTime() - this.sendingThrottleStart.getTime());
    this.sendingThrottleProgressStyle = 'width: ' + Math.max(0, Math.min(100, (100.0 - Math.round(remainingMilliseconds * 100.0 / PROGRESS_ANIMATION_TOTAL_DURATION)))) + '%';
    this.sendingThrottleRemainingSeconds = '' + Math.round( remainingMilliseconds / 100.0 ) / 10.0;
    if (this.sendingThrottleRemainingSeconds.indexOf('.') === -1) {
      this.sendingThrottleRemainingSeconds += '.0';
    }
  }

  public onButtonMessageSendClick(): void {
    this.sendMessage();
  }

  public onTextareaKeypress(event: KeyboardEvent): void {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  public focusOnTextArea(): void {
    if (!this.$refs.textarea) {
      return;
    }
    this.$nextTick(() => {
      (this.$refs.textarea as HTMLTextAreaElement).focus();
    });
  }

  private async sendMessage(): Promise<void> {
    let messageText = '';
    if (this.$refs.textarea) {
      messageText = (this.$refs.textarea as HTMLTextAreaElement).value.trim();
    }

    if (!messageText) {
      return;
    }
    if (this.isSendingThrottled) {
      this.isSendingThrottleVisible = true;
      return;
    }
    if (this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;
    this.throttleNextSending();
    await chatService.sendGroupTextMessage(this.groupId, messageText);
    this.clearTextArea();
    this.isSendingMessage = false;
    this.focusOnTextArea();
  }

  private clearTextArea(): void {
    if (!this.$refs.textarea) {
      return;
    }
    (this.$refs.textarea as HTMLTextAreaElement).value = '';
  }

}
