import store from '@/store';
import tencentChatProvider from '@/_modules/chat/providers/tencent-chat.provider';
import { IChatProvider } from '@/_modules/chat/types/chat-provider.interface';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';
import { TChatConfig } from '@/_modules/chat/types/chat-config.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';

type TGroupData = {
  group: TChatGroup;
  // messages: TChatMessage[];
  // contacts: TChatContact[];
};

export class ChatService {

  private _provider: IChatProvider;
  private _config: TChatConfig = null;
  private _joinedGroups: Map<string, TGroupData> = new Map<string, TGroupData>();

  constructor(provider: IChatProvider) {
    this._provider = provider;
    this._subscribeToProviderEvents();
  }

  public configure(config: TChatConfig): void {
    if (config === this._config) {
      return;
    }

    this._config = config;

    if (
      !config || !config.event || !config.contact
      || this._config.event.id !== config.event.id
      || this._config.contact.id !== config.contact.id
    ) {
      store.dispatch('chatStore/setIsConfigured', false);
      this.disconnect();
    } else {
      store.dispatch('chatStore/setIsConfigured', true);
    }
  }

  public get connectStatus(): ChatConnectStatus {
    return this._provider.connectStatus;
  }

  public async joinEventChatGroup(): Promise<void> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return;
    }

    const activeChatContact = ChatHelper.getChatContactFromConfig(this._config);
    const eventChatGroup = ChatHelper.getEventChatGroup(this._config.event);
    if (this._joinedGroups.get(eventChatGroup.id)) {
      return;
    }

    store.dispatch('eventChatStore/setIsLoading', true);

    try {
      let isGroupJoined = await this._provider.joinGroup(eventChatGroup.id);
      if (!isGroupJoined) {
        await this._provider.createGroup(eventChatGroup);
        isGroupJoined = await this._provider.joinGroup(eventChatGroup.id);
        if (!isGroupJoined) {
          throw new Error('Could not join event chat group.');
        }
      }
    } catch (error) {
      // TODO: handle join errors
      store.dispatch('eventChatStore/setConnectError', error);
      store.dispatch('eventChatStore/setIsContactsListLoading', false);
      return;
    }

    this._provider.updateGroupContact(activeChatContact, eventChatGroup);

    let lastMessages: TChatMessage[] = [];
    try {
      lastMessages = await this._provider.getGroupMessages(eventChatGroup.id);
    } catch (error) {
      // TODO: handle last messages error?
    }

    store.dispatch('eventChatStore/setChatContact', activeChatContact);
    store.dispatch('eventChatStore/setMessages', lastMessages);

    let contactsList: TChatContact[] = [];
    try {
      contactsList = await this._provider.getGroupContactsList(eventChatGroup.id);
      contactsList = contactsList.filter((chatContact: TChatContact): boolean => {
        return chatContact.id !== 'user_0';
      });
      store.dispatch('eventChatStore/setContactsList', contactsList);
    } catch (error) {
      store.dispatch('eventChatStore/setContactsListError', error);
    } finally {
      store.dispatch('eventChatStore/setIsContactsListLoading', false);
    }

    this._joinedGroups.set(eventChatGroup.id, {
      group: eventChatGroup,
      // messages: lastMessages,
      // contacts: contactsList,
    });

    store.dispatch('eventChatStore/setIsLoading', false);
  }

  public async joinChatGroup(chatGroup: TChatGroup): Promise<void> {
    if (this.connectStatus !== ChatConnectStatus.CONNECTED) {
      return;
    }

    const activeChatContact = ChatHelper.getChatContactFromConfig(this._config);
    if (this._joinedGroups.get(chatGroup.id)) {
      return;
    }

    store.dispatch('chatStore/joinGroupStart', chatGroup);

    // TODO: make something not error-driven
    try {
      let isGroupJoined = await this._provider.joinGroup(chatGroup.id);
      if (!isGroupJoined) {
        await this._provider.createGroup(chatGroup);
        isGroupJoined = await this._provider.joinGroup(chatGroup.id);
        if (!isGroupJoined) {
          throw new Error('Could not join event chat group.');
        }
      }
    } catch (error) {
      // TODO: handle join errors
      store.dispatch('chatStore/setLastError', error);
      return;
    }

    this._provider.updateGroupContact(activeChatContact, chatGroup);

    let lastMessages: TChatMessage[] = [];
    try {
      lastMessages = await this._provider.getGroupMessages(chatGroup.id);
    } catch (error) {
      // TODO: handle last messages error?
    }

    store.dispatch('chatStore/setMessages', { groupId: chatGroup.id, messages: lastMessages });

    // let contactsList: TChatContact[] = [];
    // try {
    //   contactsList = await this._provider.getGroupContactsList(eventChatGroup.id);
    //   contactsList = contactsList.filter((chatContact: TChatContact): boolean => {
    //     return chatContact.id !== 'user_0';
    //   });
    //   store.dispatch('eventChatStore/setContactsList', contactsList);
    // } catch (error) {
    //   store.dispatch('eventChatStore/setContactsListError', error);
    // } finally {
    //   store.dispatch('eventChatStore/setIsContactsListLoading', false);
    // }

    this._joinedGroups.set(chatGroup.id, {
      group: chatGroup,
    });

    store.dispatch('chatStore/joinGroupEnd', chatGroup.id);
  }

  public async sendGroupTextMessage(groupId: string, text: string): Promise<void> {
    const connectStatus = this.connectStatus;
    if (connectStatus === ChatConnectStatus.DISCONNECTED) {
      return;
    }

    const eventChatGroup = ChatHelper.getEventChatGroup(this._config.event);
    const activeChatContact = ChatHelper.getChatContactFromConfig(this._config);
    try {
      const newChatMessage = await this._provider.sendTextGroupMessage(groupId, { text });
      if (newChatMessage) {
        newChatMessage.from = activeChatContact;
        if (newChatMessage.to === eventChatGroup.id) {
          store.dispatch('eventChatStore/appendMessages', [
            newChatMessage
          ]);
        } else {
          store.dispatch('chatStore/appendMessages', [
            newChatMessage
          ]);
        }
      }
    } catch (error) {
      // TODO: handle errors
    }
  }

  public async disconnect(): Promise<void> {
    store.dispatch('chatStore/setConnectStatus', ChatConnectStatus.DISCONNECTED);
    this._joinedGroups.clear();
    const connectStatus = this._provider.connectStatus;
    if (
      connectStatus === ChatConnectStatus.DISCONNECTED
      || connectStatus === ChatConnectStatus.DISCONNECTING
    ) {
      return;
    }

    try {
      await this._provider.disconnect();
    } catch (error) {
      // TODO: handle disconnect error?
    }
  }

  public async connect(): Promise<void> {
    if (!this._config) {
      return;
    }
    const activeChatContact = ChatHelper.getChatContactFromConfig(this._config);
    if (!activeChatContact) {
      return;
    }
    await this._provider.connect(activeChatContact);
    this._provider.updateContact(activeChatContact);
  }

  private _subscribeToProviderEvents(): void {

    this._provider.connectStatus$.subscribe((connectStatus: ChatConnectStatus) => {
      store.dispatch('chatStore/setConnectStatus', connectStatus);
    });

    this._provider.messagesReceived$.subscribe((messages: TChatMessage[]) => {
      const eventChatGroupId = ChatHelper.getEventGroupId(this._config && this._config.event);
      const activeChatContact = ChatHelper.getChatContactFromConfig(this._config);
      if (!activeChatContact) {
        return;
      }
      if (eventChatGroupId) {
        store.dispatch('eventChatStore/appendMessages', messages.filter(message => {
          return message.to === eventChatGroupId || message.to === activeChatContact.id;
        }));
        store.dispatch('chatStore/appendMessages', messages.filter(message => {
          return message.to !== eventChatGroupId;
        }));
      }
    });

    this._provider.kickedOut$.subscribe((reason: string) => {
      store.dispatch('chatStore/kickedOut', reason);
    });
  }

}

const chatService = new ChatService(tencentChatProvider);
export default chatService;
